import React, { useState, useLayoutEffect } from "react";

// Importa o arquivo de estilos
import "./Layout.css";

// Importa componetes do Bootstrap
import { useTranslation } from "react-i18next";

// Importa os métodos de requisição
import { getData, postData } from "../../Services/apiService";

// Importa os componentes
import HeaderBar from "./Components/HeaderBar/HeaderBar";
import MenuSidebar from "./Components/MenuSidebar/MenuSidebar";
import { useAlert } from "../../Helpers/Alert/AlertContext";

export default function Layout({ children }) {
    // Hooks for alert
    const { addAlert } = useAlert();

    // Hooks for translation
    const { t, i18n } = useTranslation();

    // Define o tema da aplicação
    useLayoutEffect(() => {
        // Define o tema da aplicação
        const theme = localStorage.getItem("theme");
        if (theme) {
            document.documentElement.setAttribute("data-bs-theme", theme);
        } else {
            const configTheme = getData("/user/config?key=theme");
            configTheme.then((response) => {
                // Define o tema
                if (response.status === 200) {
                    document.documentElement.setAttribute(
                        "data-bs-theme",
                        response.body.value
                    );
                    localStorage.setItem("theme", response.body.value);
                    return;
                }

                // Define o tema padrão
                if (response.status === 404) {
                    document.documentElement.setAttribute(
                        "data-bs-theme",
                        "light"
                    );
                    localStorage.setItem("theme", "light");
                    return;
                }

                // Exibe o erro
                addAlert("danger", t("user.config.error"), response.body);
            });
        }

        // Define a linguagem do sistema
        const lang = localStorage.getItem("language");
        if (lang) {
            i18n.changeLanguage(lang);
            document.documentElement.setAttribute("lang", lang);
        } else {
            const configLang = getData("/user/config?key=lang");
            configLang.then((response) => {
                // Define a linguagem
                if (response.status === 200) {
                    i18n.changeLanguage(response.body.value);
                    localStorage.setItem("language", response.body.value);
                    document.documentElement.setAttribute(
                        "lang",
                        response.body.value
                    );
                    return;
                }

                // Define a linguagem padrão
                if (response.status === 404) {
                    i18n.changeLanguage("pt-BR");
                    localStorage.setItem("language", "pt-BR");
                    document.documentElement.setAttribute("lang", "pt-BR");
                    return;
                }

                // Exibe o erro
                addAlert("danger", t("user.config.error"), response.body);
            });
        }

        // Define o menu colapsado
        const menuCollapsed = localStorage.getItem("menuCollapsed");
        if (menuCollapsed) {
            setCollapsedMenu(menuCollapsed === "true");
        } else {
            const configMenuCollapsed = getData(
                "/user/config?key=menuCollapsed"
            );
            configMenuCollapsed.then((response) => {
                // Define o menu colapsado
                if (response.status === 200) {
                    setCollapsedMenu(response.body.value);
                    localStorage.setItem("menuCollapsed", response.body.value);
                    return;
                }

                // Define o menu colapsado padrão
                if (response.status === 404) {
                    localStorage.setItem("menuCollapsed", false);
                    return;
                }

                // Exibe o erro
                addAlert("danger", t("user.config.error"), response.body);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Menu collapsed
    const [collapsedMenu, setCollapsedMenu] = useState(false);
    const handleCollapsedChange = () => {
        const menuCollapsed = postData("/user/config", {
            key: "menuCollapsed",
            value: !collapsedMenu,
        });
        menuCollapsed.then((response) => {
            if (response.status === 200) {
                localStorage.setItem("menuCollapsed", !collapsedMenu);
                setCollapsedMenu(!collapsedMenu);
                return;
            }
            addAlert("danger", t("user.config.error"), response.body);
        });
        setCollapsedMenu(!collapsedMenu);
    };

    // Menu show on mobile
    const [showMenu, setShowMenu] = useState(false);
    const handleShowMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <div id="layout">
            <div id="menu-sidebar">
                <MenuSidebar
                    collapsed={collapsedMenu}
                    showMenu={showMenu}
                    onShowMenu={handleShowMenu}
                />
            </div>
            <div id="content">
                <HeaderBar
                    collapsed={collapsedMenu}
                    onCollapsedMenu={handleCollapsedChange}
                    onShowMenu={handleShowMenu}
                />
                <div id="content-body">{children}</div>
            </div>
        </div>
    );
}
