import React, { useEffect, useState } from "react";

import {
    Modal,
    Container,
    Row,
    Col,
    FloatingLabel,
    Form,
    Button,
} from "react-bootstrap";
import DatePicker from "../../../../../Helpers/DatePicker/DatePicker";
import { useTranslation } from "react-i18next";
import { putData } from "../../../../../Services/apiService";
import { useAlert } from "../../../../../Helpers/Alert/AlertContext";

export default function LicenseForm({ show, handleClose, license, setReload }) {
    const { t } = useTranslation(); // Translation hook
    const [btnDisable, setBtnDisable] = useState(false); // Disable button hook
    const [loading, setLoading] = useState(true); // Loading hook
    const { addAlert } = useAlert(); // Alert hook

    const [licenseForm, setLicenseForm] = useState(license); // License form hook

    // Handle the form clean and close
    const handleFormCancel = () => {
        handleClose();
    };

    // Handle the form submit
    const handleFormSubmit = async () => {
        setBtnDisable(true);
        // Call the API to update the license
        const saveLicense = putData("/license", licenseForm.id, licenseForm);
        saveLicense.then((response) => {
            if (response.status === 200) {
                addAlert(
                    "success",
                    t("licenses.form.alert.success.title"),
                    t("licenses.form.alert.success.message")
                );
                handleClose();
                setReload();
            } else {
                addAlert(
                    "danger",
                    t("licenses.form.alert.error.title"),
                    t("licenses.form.alert.error.message")
                );
            }
            setBtnDisable(false);
        });
    };

    useEffect(() => {
        if (license) {
            setLicenseForm(license);
            setLoading(false);
        }
    }, [license]);

    return (
        <>
            {loading ? null : (
                <Modal
                    show={show}
                    size="lg"
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    aria-labelledby="example-modal-sizes-title-lg"
                    className="modal-create-user"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t("licenses.form.title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col xs={12} md={12}>
                                    <FloatingLabel
                                        controlId="LicenseClientName"
                                        label={t("licenses.form.client")}
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder={t(
                                                "licenses.form.client"
                                            )}
                                            value={
                                                licenseForm.client
                                                    ? licenseForm.client.name
                                                    : ""
                                            }
                                            disabled={true}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <FloatingLabel
                                        controlId="LicenseContractDescripition"
                                        label={t("licenses.form.contract")}
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder={t(
                                                "licenses.form.contract"
                                            )}
                                            value={
                                                licenseForm.contract
                                                    ? licenseForm.contract
                                                          .description
                                                    : ""
                                            }
                                            disabled={true}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FloatingLabel
                                        controlId="LicenseApplicationName"
                                        label={t("licenses.form.application")}
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder={t(
                                                "licenses.form.application"
                                            )}
                                            value={
                                                licenseForm.application
                                                    ? licenseForm.application
                                                          .name
                                                    : ""
                                            }
                                            disabled={true}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={8}>
                                    <FloatingLabel
                                        controlId="LicenseSerial"
                                        label={t("licenses.form.serial")}
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder={t(
                                                "licenses.form.serial"
                                            )}
                                            value={licenseForm.serial || ""}
                                            disabled={true}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={4}>
                                    <FloatingLabel
                                        controlId="LicenseStatus"
                                        label={t("licenses.form.status.label")}
                                    >
                                        <Form.Select
                                            aria-label="LicenseStatus"
                                            value={licenseForm.status}
                                            onChange={(e) =>
                                                setLicenseForm({
                                                    ...licenseForm,
                                                    status: e.target.value,
                                                })
                                            }
                                        >
                                            <option
                                                key={"ACTIVE"}
                                                value={"ACTIVE"}
                                            >
                                                {t(
                                                    "licenses.form.status.active"
                                                )}
                                            </option>
                                            <option
                                                key={"INACTIVE"}
                                                value={"INACTIVE"}
                                            >
                                                {t(
                                                    "licenses.form.status.inactive"
                                                )}
                                            </option>
                                            <option
                                                key={"BLOCKED"}
                                                value={"BLOCKED"}
                                            >
                                                {t(
                                                    "licenses.form.status.blocked"
                                                )}
                                            </option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <DatePicker
                                        position="center"
                                        id="LicenseStartDate"
                                        label={t("licenses.form.expires_at")}
                                        value={licenseForm.expires_at}
                                        onChange={(date) =>
                                            setLicenseForm({
                                                ...licenseForm,
                                                expires_at: date,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="danger"
                            onClick={handleFormCancel}
                            disabled={btnDisable}
                        >
                            {t("users.form.footer.cancel")}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleFormSubmit}
                            disabled={btnDisable}
                        >
                            {t("users.form.footer.save")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
}
