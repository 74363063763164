import React from "react";

// Importa os componentes do Bootstrap
import { Navbar, Container} from "react-bootstrap";

// Importa o arquivo de estilos
import { PeopleFill } from "react-bootstrap-icons";

export default function ClientMenuCad() {
    
    return (
        <Navbar expand="lg" className="bg-body-tertiary menu-client">
            <Container fluid>
                <Navbar.Brand>
                    <PeopleFill size={50} />
                </Navbar.Brand>
            </Container>
        </Navbar>
    );
}