import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {

  const thema = localStorage.getItem('thema');
  document.documentElement.setAttribute('data-theme', thema || "ligth");


  return (
    <div style={styles.container}>
      <h1 style={styles.title}>404</h1>
      <p style={styles.message}>Oops! Página não encontrada.</p>
      <Link to="/dashboard" style={styles.link}>Voltar para a página inicial</Link>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  title: {
    fontSize: '5rem',
    marginBottom: '1rem',
  },
  message: {
    fontSize: '1.5rem',
    marginBottom: '2rem',
  },
  link: {
    fontSize: '1.2rem',
    textDecoration: 'none',
  },
};

export default NotFound;
