import React, { useState } from "react";

import "./TableHeader.css";

// Import icons
import {
    SortAlphaDown,
    SortAlphaUp,
    SortNumericDown,
    SortNumericUp,
    SortDownAlt,
    SortUp,
} from "react-bootstrap-icons";

export default function TableHeader({ table, columns, reload }) {
    // Control the order of the columns
    const [internalTable, setInternalTable] = useState(
        JSON.parse(localStorage.getItem(table))
    );

    const handlerOrder = (field, event) => {
        // Capture the shift key
        const isShiftPressed = event.shiftKey;

        // Create a new array to store the order
        let newInternalTable = {
            ...internalTable,
            order: [...internalTable.order],
        };

        if (isShiftPressed) {
            // Check if the field is already in the order array
            const index = newInternalTable.order.findIndex(
                (item) => item.field === field
            );
            if (index !== -1) {
                // If it is, change the direction
                newInternalTable.order[index].direction =
                    newInternalTable.order[index].direction === "asc"
                        ? "desc"
                        : "asc";
            } else {
                // If it is not, add the field to the array
                newInternalTable.order.push({ field, direction: "asc" });
            }
        } else {
            // If shift is not pressed, check if the field is already in the order array
            const index = newInternalTable.order.findIndex(
                (item) => item.field === field
            );
            if (index !== -1) {
                // If it is, change the direction
                newInternalTable.order[index].direction =
                    newInternalTable.order[index].direction === "asc"
                        ? "desc"
                        : "asc";
            } else {
                // If it is not, reset the order array
                newInternalTable.order = [{ field, direction: "asc" }];
            }
        }

        setInternalTable(newInternalTable);
        localStorage.setItem(table, JSON.stringify(newInternalTable));
        reload();
    };

    return (
        <thead className="table-header">
            <tr>
                {columns.map((column, index) => (
                    <th
                        key={column.field}
                        onClick={(event) => {
                            column.type !== "actions" &&
                                handlerOrder(column.field, event);
                        }}
                    >
                        <div className="ordenation">
                            {column.label}
                            {internalTable &&
                                internalTable.order.map((item, i) => {
                                    if (item.field === column.field) {
                                        if (item.direction === "asc") {
                                            return (
                                                <React.Fragment
                                                    key={item.field + "-" + i}
                                                >
                                                    <span>{i + 1}</span>
                                                    {column.type ===
                                                    "number" ? (
                                                        <SortNumericDown />
                                                    ) : column.type ===
                                                      "string" ? (
                                                        <SortAlphaDown />
                                                    ) : (
                                                        <SortDownAlt />
                                                    )}
                                                </React.Fragment>
                                            );
                                        } else {
                                            return (
                                                <React.Fragment
                                                    key={item.field + "-" + i}
                                                >
                                                    <span>{i + 1}</span>
                                                    {column.type ===
                                                    "number" ? (
                                                        <SortNumericUp />
                                                    ) : column.type ===
                                                      "string" ? (
                                                        <SortAlphaUp />
                                                    ) : (
                                                        <SortUp />
                                                    )}
                                                </React.Fragment>
                                            );
                                        }
                                    }
                                    return null;
                                })}
                        </div>
                    </th>
                ))}
            </tr>
        </thead>
    );
}
