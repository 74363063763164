import React from "react";

import Users from "../Users/Users";

export default function ClientUser({idClient}) {
    return (
        <div className="client-user-body">
           <Users userClient={true} idClient={idClient} />
        </div>
    );
}