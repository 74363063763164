import React, { useEffect, useState } from "react";

// Import components
import Layout from "../Layout/Layout";
import MenuClients from "./Components/MenuClients/MenuClients";
import FixedTable from "../Layout/Components/Table/FixedTable";
import Loading from "../Layout/Components/Loading";
import { useAlert } from "../../Helpers/Alert/AlertContext";
import ConfirmDialog from "../../Helpers/Dialog/ConfirmDialog";

// Import the CSS file
import "./Client.css";

// Import the translation hook
import { useTranslation } from "react-i18next";

// Import API service
import { getData, deleteData } from "../../Services/apiService";
import { mascCpfCnpj } from "../../Helpers/Masc";
import { useNavigate } from "react-router-dom";
import { PencilSquare, Trash } from "react-bootstrap-icons";

export default function ClientList() {
    // Hook to use the alert functions
    const { addAlert } = useAlert();

    // Hook to use the translation functions
    const { t } = useTranslation();

    // Hook to navigate
    const navigate = useNavigate();

    // Set the title of the page
    document.title = t("client.title-page.list");

    // Set loading
    const [loading, setLoading] = useState(true);

    // List the clients
    const [clients, setClients] = useState([]);
    const [searsh, setSearsh] = useState("");
    const handleSearsh = async (value) => {
        setSearsh(value);
    };
    const cleanSearsh = async () => {
        setSearsh("");
    };

    const handlerListClients = async () => {
        // Get the query string from the local storage
        let queryString = JSON.parse(localStorage.getItem("clients"));
        if (!queryString) {
            queryString = {
                page: 1,
                limit: 10,
                totalItems: 0,
                order: [{ field: "name", direction: "asc" }],
            };
            localStorage.setItem("clients", JSON.stringify(queryString));
        }

        // Define the URI
        let uri = `/client?page=${queryString.page}&limit=${queryString.limit}`;

        // Adicionar ordenações à URI
        const orderString = encodeURIComponent(
            JSON.stringify(queryString.order)
        );
        uri += `&order=${orderString}`;

        // Adicionar filtros à URI
        if (searsh !== "") {
            uri += `&where=${searsh}`;
        }

        // Get the data from the API
        const getClients = getData(uri);
        getClients.then((response) => {
            if (response.status === 200) {
                setClients(response.body.data);
                queryString.lastPage = response.body.total_pages;
                queryString.totalItems = response.body.total_items;
                localStorage.setItem("clients", JSON.stringify(queryString));
                setLoading(false);
                return;
            }
            addAlert("danger", t("client.alert.error"), response.body);
        });
    };

    // Reload the data
    const [reload, setReload] = useState(false);
    useEffect(() => {
        setLoading(true);
        handlerListClients();
        // eslint-disable-next-line
    }, [reload, searsh]);

    // Edit the client
    const editClient = (client) => {
        navigate(`/clients/${client.id}/edit`);
    };

    // Delete the client
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [itemDelete, setItemDelete] = useState(0);

    const deleteClient = (client) => {
        // Mostrar o modal de confirmação
        setItemDelete(client.id);
        setShowConfirmDialog(true);
    };

    // Confirm the exclusion
    const handleConfirm = () => {
        setShowConfirmDialog(false); // Fechar o modal

        // Excluir o item
        const deleteItem = deleteData(`/client`, itemDelete);
        deleteItem.then((response) => {
            if (response.status === 200) {
                addAlert(
                    "success",
                    t("client.alert.success"),
                    response.body.message
                );
                setReload(reload + 1);
                return;
            }

            if (response.status === 409) {
                if (response.body.code === "23000") {
                    addAlert(
                        "danger",
                        t("client.alert.delete.error"),
                        t("client.alert.delete.error-23000")
                    );
                    return;
                }
                addAlert(
                    "danger",
                    t("client.alert.delete.error"),
                    response.body
                );
                return;
            }
            addAlert("danger", t("client.alert.error"), response.body);
        });
    };

    const handleClose = () => {
        setShowConfirmDialog(false); // Fechar o modal sem excluir
    };

    // List data from the client table
    const tableColumns = [
        { field: "id", label: "#", type: "number" },
        { field: "name", label: t("client.table.name"), type: "string" },
        {
            field: "cpf_cnpj",
            label: t("client.table.document"),
            type: "string",
            masc: mascCpfCnpj,
        },
        {
            field: "is_active",
            label: t("client.table.is_inactive"),
            type: "boolean",
        },
        {
            field: "create_at",
            label: t("client.table.create_at"),
            type: "date-time",
        },
        {
            field: "name_create_user",
            label: t("client.table.create_user"),
            type: "string",
        },
        {
            field: "update_at",
            label: t("client.table.update_at"),
            type: "date-time",
        },
        {
            field: "name_update_user",
            label: t("client.table.update_user"),
            type: "string",
        },
        {
            field: "actions",
            label: t("client.table.actions"),
            type: "actions",
            actions: [
                { icon: <PencilSquare/>, handle: editClient },
                { icon: <Trash />, handle: deleteClient },
            ]
        },
    ];

    return (
        <>
            <Layout>
                <ConfirmDialog
                    show={showConfirmDialog}
                    handleClose={handleClose}
                    handleConfirm={handleConfirm}
                    title="Confirmação de Exclusão"
                    message="Tem certeza de que deseja excluir este item?"
                />
                <MenuClients searsh={handleSearsh} clean={cleanSearsh} />
                {loading ? (
                    <Loading />
                ) : (
                    <FixedTable
                        table="clients"
                        columns={tableColumns}
                        data={clients}
                        reload={() => setReload(reload + 1)}
                    />
                )}
            </Layout>
        </>
    );
}
