import React, { useState } from "react";

import {
    Button,
    Row,
    Col,
    FloatingLabel,
    Form,
    Container,
} from "react-bootstrap";
import Logo from "../../Assets/Images/logo_primus.png";
import { useTranslation } from "react-i18next";
import { validateEmail } from "../../Helpers/Form";
import { postData } from "../../Services/apiService";
import { useAlert } from "../../Helpers/Alert/AlertContext";

export default function UserForgot() {
    // Translation
    const { t } = useTranslation();

    const { addAlert } = useAlert();
    // Handle form validation
    const [email, setEmail] = useState(null);
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        const newErrors = {};

        console.log(email);
        // Email is required
        if (!email) newErrors.email = t("users.forgot-password.email.required");
        // Invalid email
        else if (!validateEmail(email))
            newErrors.email = t("users.forgot-password.email.invalid");

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const [btnDisable, setBtnDisable] = useState(false);
    const handleSubmit = (event) => {
        event.preventDefault();

        if (validateForm()) {
            // Send request to server
            setBtnDisable(true);
            const forgotPassword = postData("/recovery", {
                "email": email,
            });
            forgotPassword.then((response) => {
                if (response.status === 200) {
                    addAlert("success", t("users.forgot-password.success.title"), t("users.forgot-password.success.message"));
                } else {
                    console.error(response);
                    addAlert("danger", t("users.forgot-password.error.title"), t("users.forgot-password.error.message"));
                }
            });
        }
    };

    return (
        <>
            <div className="activation-container">
                <div className="activation-title">
                    <h1>{t("users.forgot-password.title")}</h1>
                </div>
                <div className="activation-image">
                    <img src={Logo} alt="activation" />
                </div>
                <Container style={{ maxWidth: "700px", margin: "20px" }}>
                    <Row>
                        <Col>
                            <Form>
                                <FloatingLabel
                                    controlId="floatingEmail"
                                    label={t(
                                        "users.forgot-password.email.label"
                                    )}
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="email"
                                        placeholder={t(
                                            "users.forgot-password.email.placeholder"
                                        )}
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                <Button
                    onClick={handleSubmit}
                    variant="primary"
                    size="lg"
                    style={{ width: "200px" }}
                    disabled={btnDisable}
                >
                    {t("users.forgot-password.button")}
                </Button>
            </div>
        </>
    );
}
