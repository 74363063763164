function masc(valor, mascara) {
    let i = 0;
    const valorFormatado = mascara.replace(/#/g, () => valor[i++] || "");
    return valorFormatado;
}

function mascCpfCnpj(valor) {
    if (valor.length <= 11) {
        return masc(valor, "###.###.###-##");
    } else {
        return masc(valor, "##.###.###/####-##");
    }
}

function mascCep(valor) {
    return masc(valor, "#####-###");
}

function mascPhone(valor) {
    return masc(valor, "(##) ####-####");
}

function mascCellPhone(valor) {
    return masc(valor, "(##) #####-####");
}

module.exports = {
    mascCpfCnpj,
    mascCep,
    mascPhone,
    mascCellPhone,
};
