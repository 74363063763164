import React, { useEffect, useState } from "react";
import { Offcanvas, Nav, Navbar } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
    Gear,
    BarChartFill,
    PeopleFill,
    PersonBoundingBox,
} from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import "./MenuSidebar.css";

import Logo from "../../../../Assets/Images/logo_primus3.webp";

export default function Sidebar({ collapsed, showMenu, onShowMenu }) {
    // Hook para tradução
    const { t } = useTranslation();

    // Hook para mostrar o texto do menu
    const [showSpan, setShowSpan] = useState(false);
    useEffect(() => {
        let timer;
        if (collapsed) {
            timer = setTimeout(() => {
                setShowSpan(true);
            }, 150);
        } else {
            setShowSpan(false);
        }

        return () => clearTimeout(timer);
    }, [collapsed]);

    // Hook para pegar a localização da página
    const location = useLocation();
    const currentPath = location.pathname;
    const isActive = (path) => (currentPath === path ? "active" : "");

    return (
        <>
            {/* Sidebar fixa no lado esquerdo */}
            <div
                className={`sidebar ${
                    collapsed ? "expanded" : "collapsed"
                } d-none d-md-block`}
            >
                <Nav className="flex-column">
                    <Navbar.Brand
                        className={`header-menu ${
                            collapsed ? "expanded" : "collapsed"
                        }`}
                    >
                        <div className="logo-menu">
                            <img src={Logo} alt="logo-primus" />
                        </div>
                        {collapsed && showSpan && <span>Primus</span>}
                    </Navbar.Brand>
                    <Nav.Link
                        href="/dashboard"
                        className={`d-flex align-items-center ${isActive(
                            "/dashboard"
                        )}`}
                    >
                        <div className="menu-icon">
                            <BarChartFill />
                        </div>
                        {collapsed && showSpan && (
                            <span>{t("menu.dashboard")}</span>
                        )}
                    </Nav.Link>
                    <Nav.Link
                        href="/clients"
                        className={`d-flex align-items-center ${isActive(
                            "/clients"
                        )}`}
                    >
                        <div className="menu-icon">
                            <PeopleFill />
                        </div>
                        {collapsed && showSpan && (
                            <span>{t("menu.client")}</span>
                        )}
                    </Nav.Link>
                    <Nav.Link
                        href="/settings"
                        className={`d-flex align-items-center ${isActive(
                            "/settings"
                        )}`}
                    >
                        <div className="menu-icon">
                            <Gear />
                        </div>
                        {collapsed && showSpan && (
                            <span>{t("menu.settings")}</span>
                        )}
                    </Nav.Link>
                    <Nav.Link
                        href="/users"
                        className={`d-flex align-items-center ${isActive(
                            "/users"
                        )}`}
                    >
                        <div className="menu-icon">
                            <PersonBoundingBox />
                        </div>
                        {collapsed && showSpan && (
                            <span>{t("menu.users")}</span>
                        )}
                    </Nav.Link>
                </Nav>
            </div>

            {/* Sidebar Offcanvas para telas menores */}
            <Offcanvas
                show={showMenu}
                onHide={onShowMenu}
                className="d-md-none"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="flex-column">
                        <Nav.Link
                            href="/dashboard"
                            className={`d-flex align-items-center ${isActive(
                                "/dashboard"
                            )}`}
                        >
                            <div className="menu-icon">
                                <BarChartFill />
                            </div>
                            <span>{t("menu.dashboard")}</span>
                        </Nav.Link>
                        <Nav.Link
                            href="/clients"
                            className={`d-flex align-items-center ${isActive(
                                "/clients"
                            )}`}
                        >
                            <div className="menu-icon">
                                <PeopleFill />
                            </div>
                            <span>{t("menu.client")}</span>
                        </Nav.Link>
                        <Nav.Link
                            href="/settings"
                            className={`d-flex align-items-center ${isActive(
                                "/settings"
                            )}`}
                        >
                            <div className="menu-icon">
                                <Gear />
                            </div>
                            <span>{t("menu.settings")}</span>
                        </Nav.Link>
                        <Nav.Link
                            href="/users"
                            className={`d-flex align-items-center ${isActive(
                                "/users"
                            )}`}
                        >
                            <div className="menu-icon">
                                <PersonBoundingBox />
                            </div>
                            <span>{t("menu.users")}</span>
                        </Nav.Link>
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
