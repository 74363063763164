import React, { useState } from "react";

import {
    Modal,
    Button,
    Container,
    Row,
    Col,
    FloatingLabel,
    Form,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { validateEmail, validatePassword } from "../../../Helpers/Form";
import { postData, putData } from "../../../Services/apiService";
import { useAlert } from "../../../Helpers/Alert/AlertContext";

export default function UserForm({ show, profiles, handleClose, user, idClient }) {
    // Hooks for translation
    const { t } = useTranslation();

    // Hook to use the alert functions
    const { addAlert } = useAlert();

    // Form state
    const [userForm, setUserForm] = useState(user);

    // Handle form submit
    const [btnDisable, setBtnDisable] = useState(false);
    const handleFormSubmit = () => {
        // Valida o formulario
        if (validateForm()) {
            // desbilita os botoes enqunto processa do cadastro
            setBtnDisable(true);

            // Montagem da url
            let url = "";
            if (idClient) {
                url += `/client/${idClient}`;
            }
            url += "/user";

            // Envia requisicao para o servidor
            let save = null;
            if (userForm.id) {
                save = putData(url, userForm.id, userForm);
            } else {
                save = postData(url, userForm);
            }

            // Espera a resposta do servidor
            save.then((response) => {
                if (response.status === 200) {
                    addAlert(
                        "success",
                        t("users.form.alert.success.title"),
                        t("users.form.alert.success.message")
                    );
                    handleClose();
                    setBtnDisable(false);
                    setUserForm({});
                    return;
                }

                console.log(response);
                addAlert(
                    "danger",
                    `Error: ${response.status}`,
                    t("users.form.alert.error.message")
                );
                setBtnDisable(false);
            });
        }
    };

    const handleFormCancel = () => {
        setUserForm({});
        setErrors({});
        handleClose();
    };

    // Handle form validation
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        const newErrors = {};
        // Name is required
        if (!userForm.name)
            newErrors.name = t("users.form.user-data.error.name.required");
        // Email is required
        if (!userForm.email)
            newErrors.email = t("users.form.user-data.error.email.required");
        // Invalid email
        if (!validateEmail(userForm.email))
            newErrors.email = t("users.form.user-data.error.email.invalid");
        if (!userForm.profile || userForm.profile.id === 0)
            newErrors.profile = t(
                "users.form.user-data.error.profile.required"
            );

        // If it is an edition, it is not necessary to validate the password
        if (userForm.id) return Object.keys(newErrors).length === 0;

        // Password is required
        if (!userForm.password)
            newErrors.password = t(
                "users.form.user-data.error.password.required"
            );
        // Confirm password is required
        if (!userForm.confirmPassword)
            newErrors.confirmPassword = t(
                "users.form.user-data.error.confirm-password.required"
            );
        // Password and confirm password must be the same
        if (userForm.password !== userForm.confirmPassword)
            newErrors.confirmPassword = t(
                "users.form.user-data.error.confirm-password.different"
            );
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle password
    const handlePassword = (value) => {
        const newErrors = {};
        if (!validatePassword(value))
            newErrors.password = t(
                "users.form.user-data.error.password.invalid"
            );
        setErrors(newErrors);
        setUserForm({
            ...userForm,
            password: value,
        });
    };

    // Handle confirm password
    const handleConfirmPassword = (value) => {
        const newErrors = {};
        if (userForm.password !== value)
            newErrors.confirmPassword = t(
                "users.form.user-data.error.confirm-password.different"
            );
        setErrors(newErrors);
        setUserForm({
            ...userForm,
            confirmPassword: value,
        });
    };
    
    return (
        <>
            <Modal
                show={show}
                size="lg"
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                aria-labelledby="example-modal-sizes-title-lg"
                className="modal-create-user"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {user
                            ? t("users.form.header.title.edit")
                            : t("users.form.header.title.new")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>
                                <FloatingLabel
                                    controlId="userName"
                                    label={t("users.form.user-data.name")}
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder={t(
                                            "users.form.user-data.name"
                                        )}
                                        value={userForm.name || ""}
                                        onChange={(e) => {
                                            setUserForm({
                                                ...userForm,
                                                name: e.target.value,
                                            });
                                        }}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12}>
                                <FloatingLabel
                                    controlId="userEmail"
                                    label={t("users.form.user-data.email")}
                                >
                                    <Form.Control
                                        type="email"
                                        placeholder={t(
                                            "users.form.user-data.email"
                                        )}
                                        value={userForm.email || ""}
                                        onChange={(e) => {
                                            setUserForm({
                                                ...userForm,
                                                email: e.target.value,
                                            });
                                        }}
                                        isInvalid={!!errors.email}
                                        autoComplete="new-email"
                                        {...(userForm.id
                                            ? { disabled: true }
                                            : {})}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} md={12}>
                                <FloatingLabel
                                    controlId="userSelectProfile"
                                    label={t("users.form.user-data.profile")}
                                >
                                    <Form.Select
                                        aria-label="Profile"
                                        value={
                                            userForm.profile
                                                ? userForm.profile.id
                                                : 0
                                        }
                                        onChange={(e) => {
                                            setUserForm({
                                                ...userForm,
                                                profile: {
                                                    id: parseInt(
                                                        e.target.value
                                                    ),
                                                },
                                            });
                                        }}
                                        isInvalid={!!errors.profile}
                                    >
                                        <option value={0}></option>
                                        {profiles && profiles.map((profile) => (
                                            <option
                                                key={profile.id}
                                                value={profile.id}
                                            >
                                                {profile.description}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.profile}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        {userForm.id ? ( // Se for edição nao exibe campos de senha.
                            ""
                        ) : (
                            <Row>
                                <Col xs={12} md={6}>
                                    <FloatingLabel
                                        controlId="userPassword"
                                        label={t(
                                            "users.form.user-data.password"
                                        )}
                                    >
                                        <Form.Control
                                            type="password"
                                            placeholder={t(
                                                "users.form.user-data.password"
                                            )}
                                            value={userForm.password || ""}
                                            onChange={(e) => {
                                                handlePassword(e.target.value);
                                            }}
                                            isInvalid={!!errors.password}
                                            autoComplete="new-password"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.password}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FloatingLabel
                                        controlId="userConfirmPassword"
                                        label={t(
                                            "users.form.user-data.confirm-password"
                                        )}
                                    >
                                        <Form.Control
                                            type="password"
                                            placeholder={t(
                                                "users.form.user-data.confirm-password"
                                            )}
                                            value={
                                                userForm.confirmPassword || ""
                                            }
                                            onChange={(e) => {
                                                handleConfirmPassword(
                                                    e.target.value
                                                );
                                            }}
                                            isInvalid={!!errors.confirmPassword}
                                            autoComplete="new-password"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.confirmPassword}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        )}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        onClick={handleFormCancel}
                        disabled={btnDisable}
                    >
                        {t("users.form.footer.cancel")}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleFormSubmit}
                        disabled={btnDisable}
                    >
                        {t("users.form.footer.save")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
