import React, { useEffect } from "react";

import ContractMenu from "./Components/ContractMenu";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../../Helpers/Alert/AlertContext";
import Loading from "../../../Layout/Components/Loading";
import FixedTable from "../../../Layout/Components/Table/FixedTable";

import { getData } from "../../../../Services/apiService";

export default function Contracts({ idClient, contract }) {
    const { t } = useTranslation(); // Translation hook
    const { addAlert } = useAlert(); // Alert hook
    const [loading, setLoading] = React.useState(true); // Loading hook
    const [reload, setReload] = React.useState(0); // Reload hook

    // List the contracts
    const [contracts, setContracts] = React.useState([]);
    const [searsh, setSearsh] = React.useState("");
    const listContracts = async () => {
        console.log("List contracts: ", idClient);
        // Get the query string from the local storage
        let queryString = JSON.parse(localStorage.getItem("contracts"));
        if (!queryString) {
            queryString = {
                page: 1,
                limit: 10,
                totalItems: 0,
                order: [{ field: "id", direction: "asc" }],
            };
            localStorage.setItem("contracts", JSON.stringify(queryString));
        }

        // Get the licenses for the client
        let uri = `/contract/${idClient}?page=${queryString.page}&limit=${queryString.limit}`;

        // Adicionar ordenações à URI
        const orderString = encodeURIComponent(
            JSON.stringify(queryString.order)
        );
        uri += `&order=${orderString}`;

        // Adicionar filtros à URI
        if (searsh !== "") {
            uri += `&where=${searsh}`;
        }

        // Await the response
        const getLicenses = getData(uri);
        getLicenses.then((response) => {
            if (response.status === 200) {
                setContracts(response.body.data);
                contract(response.body.data);
                queryString.lastPage = response.body.total_pages;
                queryString.totalItems = response.body.total_items;
                localStorage.setItem("contracts", JSON.stringify(queryString));
                setLoading(false);
                return;
            }
            addAlert("danger", t("license.alert.error"), response.body);
        });
    };
    useEffect(() => {
        if (idClient) {
            const list = async () => {
                await listContracts();
            };
            list();
        } else {
            setLoading(false);
        }
        // eslint-disable-next-line
    }, []);

    // Column definitions
    const tableColumns = [
        { field: "id", label: "#", type: "number" },
        {
            field: "client.name",
            label: t("contract.table.client"),
            type: "string",
        },
        {
            field: "description",
            label: t("contract.table.description"),
            type: "string",
        },
        {
            field: "is_active",
            label: t("contract.table.is_active"),
            type: "boolean",
        },
        {
            field: "create_user.name",
            label: t("contract.table.create_user"),
            type: "string",
        },
        {
            field: "create_at",
            label: t("contract.table.create_at"),
            type: "date",
        },
        {
            field: "update_user.name",
            label: t("contract.table.update_user"),
            type: "string",
        },
        {
            field: "update_at",
            label: t("contract.table.update_at"),
            type: "date",
        },
    ];

    return (
        <>
            <ContractMenu />
            {loading ? (
                <Loading />
            ) : (
                <FixedTable
                    table="contracts"
                    columns={tableColumns}
                    data={contracts}
                    reload={() => setReload(reload + 1)}
                />
            )}
        </>
    );
}
