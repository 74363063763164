async function getAddress(cep) {
    return new Promise(async (resolve, reject) => {
        const numericValue = cep.replace(/\D/g, ""); // Filtra apenas os caracteres numéricos
        if (numericValue.length === 8) {
            try {
                const response = await fetch(
                    `https://viacep.com.br/ws/${numericValue}/json/`
                );
                const data = await response.json();
                resolve(data);
            } catch (error) {
                reject({ error: true, massage: error });
            }
        }
        reject({ erro: true, massage: "error.zip_code.invalid" });
    });
}

module.exports = { getAddress };
