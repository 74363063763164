import React, { useEffect, useState } from "react";

import Loading from "../Layout/Components/Loading";
import UserMenu from "./Components/UserMenu";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../Helpers/Alert/AlertContext";
import FixedTable from "../Layout/Components/Table/FixedTable";
import { getData, deleteData } from "../../Services/apiService";
import UserForm from "./Components/UserForm";
import ConfirmDialog from "../../Helpers/Dialog/ConfirmDialog";
import { KeyFill, PencilSquare, Trash } from "react-bootstrap-icons";
import UserPasswordForm from "./Components/UserPasswordForm";

// Import the CSS file
import "./Users.css";
import Layout from "../Layout/Layout";

export default function Users({ userClient, idClient }) {
    // Hook to use the translation functions
    const { t } = useTranslation();

    // Set the title of the page
    document.title = t("users.title-page.list");

    // Hook to use the alert functions
    const { addAlert } = useAlert();

    // Set loading
    const [loading, setLoading] = useState(true);

    // Hook to reload the table
    const [reload, setReload] = useState(0);

    // List of Users
    const [users, setUsers] = useState([]);
    const [searsh, setSearsh] = useState("");
    const handleSearsh = async (value) => {
        setSearsh(value);
    };
    const cleanSearsh = async () => {
        setSearsh("");
    };

    // List of profiles
    const [profiles, setProfiles] = useState([]);
    const handleListProfiles = async () => {
        const getProfiles = getData("/user/profile");
        getProfiles.then((response) => {
            if (response.status === 200) {
                setProfiles(response.body.data);
                return;
            }
            addAlert("danger", t("error.internal"), response.body);
        });
    };

    // List of users
    const handlerListUser = async () => {
        if (userClient && !idClient) {
            setLoading(false);
            return;
        }

        // Get the query string from the local storage
        let queryString = JSON.parse(localStorage.getItem("users"));
        if (!queryString) {
            queryString = {
                page: 1,
                limit: 10,
                totalItems: 0,
                order: [{ field: "name", direction: "asc" }],
            };
            localStorage.setItem("users", JSON.stringify(queryString));
        }

        let uri = "";

        // Define the URI
        if (idClient) {
            uri = `/client/${idClient}/users?page=${queryString.page}&limit=${queryString.limit}`;
        } else {
            uri = `/user?page=${queryString.page}&limit=${queryString.limit}`;
        }

        // Adicionar ordenações à URI
        const orderString = encodeURIComponent(
            JSON.stringify(queryString.order)
        );
        uri += `&order=${orderString}`;

        // Adicionar filtros à URI
        if (searsh !== "") {
            uri += `&where=${searsh}`;
        }

        // Get the data from the API
        const getUsers = getData(uri);
        getUsers.then((response) => {
            if (response.status === 200) {
                setUsers(response.body.data);
                queryString.lastPage = response.body.total_pages;
                queryString.totalItems = response.body.total_items;
                localStorage.setItem("users", JSON.stringify(queryString));
                setLoading(false);
                return;
            }
            addAlert("danger", t("error.internal"), response.body);
        });
    };

    // Edit User
    const [user, setUser] = useState({});
    const editUser = (user) => {
        setUser(user);
        handleFormShow();
    };

    // Delete the client
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [itemDelete, setItemDelete] = useState(0);
    const deleteUser = (user) => {
        const loggedUser = JSON.parse(localStorage.getItem("auth"));
        console.log(loggedUser);
        if (loggedUser.IdUser === user.id) {
            addAlert(
                "danger",
                t("users.delete.title"),
                t("users.delete.error.logged")
            );
            return;
        }
        setItemDelete(user.id);
        setShowConfirmDialog(true);
    };
    const handleConfirm = () => {
        setShowConfirmDialog(false); // Fechar o modal

        // Excluir o item
        const deleteItem = deleteData(`/user`, itemDelete);
        deleteItem.then((response) => {
            if (response.status === 200) {
                addAlert(
                    "success",
                    t("users.delete.title"),
                    t("users.delete.success")
                );
                setReload(reload + 1);
                return;
            }
            addAlert("danger", t("error.internal"), response.body);
        });
    };
    const handleClose = () => {
        setItemDelete(0);
        setShowConfirmDialog(false);
    };

    // Load the data
    useEffect(() => {
        const list = async () => {
            await handleListProfiles();
            await handlerListUser();
        };
        list();
        // eslint-disable-next-line
    }, [reload, searsh]);

    // Manupuladores do formulário
    const [formShow, setFormShow] = React.useState(false);
    const handleFormShow = () => {
        setFormShow(true);
    };
    const handleFormClose = () => {
        setUser({});
        setFormShow(false);
        setReload(reload + 1);
    };

    const [showPasswordChange, setShowPasswordChange] = useState(false);
    const [userChangePassword, setUserChangePassword] = useState({});
    const handleClosePassword = () => {
        setShowPasswordChange(false);
    };
    const handlePassword = (user) => {
        setUserChangePassword(user);
        setShowPasswordChange(true);
    };

    // List data from the users table
    const tableColumns = [
        { field: "id", label: "#", type: "number" },
        { field: "name", label: t("users.table.name"), type: "string" },
        { field: "email", label: t("users.table.email"), type: "string" },
        {
            field: "profile.description",
            label: t("users.table.profile"),
            type: "string",
        },
        {
            field: "create_at",
            label: t("users.table.create_at"),
            type: "date-time",
        },
        {
            field: "create_user.name",
            label: t("users.table.create_user"),
            type: "string",
        },
        {
            field: "update_at",
            label: t("users.table.update_at"),
            type: "date-time",
        },
        {
            field: "update_user.name",
            label: t("users.table.update_user"),
            type: "string",
        },
        {
            field: "actions",
            label: t("users.table.actions"),
            type: "actions",
            actions: [
                { icon: <KeyFill />, handle: handlePassword },
                { icon: <PencilSquare />, handle: editUser },
                { icon: <Trash />, handle: deleteUser },
            ],
        },
    ];

    const Users = () => {
        return (
            <>
                <UserPasswordForm
                    user={userChangePassword}
                    show={showPasswordChange}
                    handleClose={handleClosePassword}
                />
                <ConfirmDialog
                    show={showConfirmDialog}
                    handleClose={handleClose}
                    handleConfirm={handleConfirm}
                    title={t("users.delete.title")}
                    message={t("users.delete.confirm")}
                />
                <UserForm
                    show={formShow}
                    profiles={profiles}
                    handleClose={handleFormClose}
                    user={user}
                    idClient={idClient}
                />
                <UserMenu
                    handleFormShow={handleFormShow}
                    searsh={handleSearsh}
                    clean={cleanSearsh}
                    userClient={userClient}
                    idClient={idClient}
                />
                {loading ? (
                    <Loading />
                ) : (
                    <FixedTable
                        table="users"
                        columns={tableColumns}
                        data={users}
                        reload={() => setReload(reload + 1)}
                    />
                )}
            </>
        );
    };

    return (
        <>
            {userClient ? (
                <Users idClient={idClient} />
            ) : (
                <Layout>
                    <Users />
                </Layout>
            )}
        </>
    );
}
