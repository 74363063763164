import React, { useState, useEffect } from "react";
import { Table, ListGroup } from "react-bootstrap";

// Import the components
import TableHeader from "./Components/TableHeader/TableHeader";
import TableFooter from "./Components/TableFooter/TableFooter";

// Import the CSS file
import "./FixedTable.css";

// Import the formater data
import { format } from "date-fns";

// Import the translation hook
import { useTranslation } from "react-i18next";

import { Check2Circle, XCircle } from "react-bootstrap-icons";
export default function FixedTable({ table, columns, data, menu, reload }) {
    // Get the translation function
    const { t } = useTranslation();
    const [contextMenu, setContextMenu] = useState({
        visible: false,
        x: 0,
        y: 0,
    });
    const [idMenu, setIdMenu] = useState(0);

    // Function to format the date
    const formatDate = (date, type) => {
        if (type === "date-time") {
            return format(new Date(date), t("date.formatTime"));
        }

        return format(new Date(date), t("date.format"));
    };

    function getNestedValue(obj, path) {
        return path.split(".").reduce((acc, key) => acc && acc[key], obj);
    }

    const handleContextMenu = (event, data) => {
        event.preventDefault();
        setIdMenu(data);
        setContextMenu({
            visible: true,
            x: event.clientX,
            y: event.clientY,
        });
    };

    const handleClick = () => {
        setContextMenu({ visible: false, x: 0, y: 0 });
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, []);

    return (
        <div className="table-conteiner">
            <div className="table-scrollable">
                <Table hover>
                    <TableHeader
                        table={table}
                        columns={columns}
                        reload={reload}
                    />
                    <tbody>
                        {data &&
                            data.map((row, index) => (
                                <tr
                                    key={index}
                                    onContextMenu={
                                        menu
                                            ? (e) => {
                                                  handleContextMenu(e, row);
                                              }
                                            : null
                                    }
                                >
                                    {columns.map((column, colIndex) => {
                                        const style = column.style
                                            ? Object.keys(column.style).reduce(
                                                  (acc, key) => {
                                                      acc[key] =
                                                          typeof column.style[
                                                              key
                                                          ] === "function"
                                                              ? column.style[
                                                                    key
                                                                ](row)
                                                              : column.style[
                                                                    key
                                                                ];
                                                      return acc;
                                                  },
                                                  {}
                                              )
                                            : {};
                                        return (
                                            <td
                                                key={row["id"] + "-" + colIndex}
                                                className={
                                                    column.type !== "string"
                                                        ? "table-column-center"
                                                        : null
                                                }
                                                style={style}
                                            >
                                                {column.type === "date" ? (
                                                    getNestedValue(
                                                        row,
                                                        column.field
                                                    ) &&
                                                    formatDate(
                                                        getNestedValue(
                                                            row,
                                                            column.field
                                                        ),
                                                        column.type
                                                    )
                                                ) : column.type ===
                                                  "date-time" ? (
                                                    getNestedValue(
                                                        row,
                                                        column.field
                                                    ) &&
                                                    formatDate(
                                                        getNestedValue(
                                                            row,
                                                            column.field
                                                        ),
                                                        column.type
                                                    )
                                                ) : column.type ===
                                                  "boolean" ? (
                                                    getNestedValue(
                                                        row,
                                                        column.field
                                                    ) ? (
                                                        <Check2Circle
                                                            style={{
                                                                color:
                                                                    "#14870c",
                                                            }}
                                                        />
                                                    ) : (
                                                        <XCircle
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        />
                                                    )
                                                ) : column.type ===
                                                  "!boolean" ? (
                                                    getNestedValue(
                                                        row,
                                                        column.field
                                                    ) ? (
                                                        <XCircle
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        />
                                                    ) : (
                                                        <Check2Circle
                                                            style={{
                                                                color:
                                                                    "#14870c",
                                                            }}
                                                        />
                                                    )
                                                ) : column.type ===
                                                  "actions" ? (
                                                    <div className="table-column-action">
                                                        {column.actions &&
                                                            column.actions.map(
                                                                (
                                                                    action,
                                                                    index
                                                                ) => (
                                                                    <span
                                                                        key={
                                                                            row[
                                                                                "id"
                                                                            ] +
                                                                            index
                                                                        }
                                                                        onClick={() =>
                                                                            action.handle(
                                                                                row
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            action.icon
                                                                        }
                                                                    </span>
                                                                )
                                                            )}
                                                    </div>
                                                ) : column.masc ? (
                                                    column.masc(
                                                        getNestedValue(
                                                            row,
                                                            column.field
                                                        )
                                                    )
                                                ) : (
                                                    getNestedValue(
                                                        row,
                                                        column.field
                                                    )
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </div>
            <TableFooter
                table={table}
                countColumns={columns.length}
                reload={reload}
            />
            <ul
                className="context-menu"
                style={{ top: contextMenu.y, left: contextMenu.x }}
            >
                {menu && contextMenu.visible && (
                    <ListGroup
                        style={{ top: contextMenu.y, left: contextMenu.x }}
                    >
                        {menu.map((item, index) => (
                            <ListGroup.Item
                                className="context-menu-item"
                                key={index}
                                onClick={() => {
                                    item.handle(idMenu);
                                    setContextMenu({
                                        visible: false,
                                        x: 0,
                                        y: 0,
                                    });
                                }}
                                {...(typeof item.disabled === 'function' && item.disabled(idMenu) && { disabled: true })}
                            >
                                <span className="context-menu-icon">
                                    {item.icon}
                                </span>
                                {item.label}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                )}
            </ul>
        </div>
    );
}
