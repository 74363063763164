import React, { useState } from "react";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    Container,
    Row,
    Col,
    FloatingLabel,
    Form,
    Button,
} from "react-bootstrap";
import { putData } from "../../Services/apiService";
import { useAlert } from "../../Helpers/Alert/AlertContext";
import { validatePassword } from "../../Helpers/Form";
import Logo from "../../Assets/Images/logo_primus.png";

export default function UserRecovery() {
    const { t } = useTranslation();
    const { addAlert } = useAlert();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    const token = queryParams.get("token");

    // Hook para os dados do formulário
    const [userForm, setUserForm] = useState({});

    // Enviar dados do formulário
    const [btnDisable, setBtnDisable] = useState(false);
    const handleFormSubmit = () => {
        if (validateForm()) {
            setBtnDisable(true);
            // Envia os dados do formulário
            const changePassword = putData(`/user/recovery`, id, {
                token: token,
                password: userForm.password,
                confirm_password: userForm.confirmPassword,
            });
            changePassword.then((response) => {
                if (response.status === 200) {
                    addAlert(
                        "success",
                        t("users.form.password-change.success.title"),
                        t("users.form.password-change.success.message")
                    );
                    setUserForm({});
                    setBtnDisable(false);
                } else {
                    console.error(response.body);
                    addAlert(
                        "danger",
                        t("users.form.password-change.error.title") +
                            " " +
                            response.status,
                        response.body
                    );
                    setBtnDisable(false);
                }
            });
        }
    };

    const [errors, setErrors] = useState({});
    const validateForm = () => {
        const newErrors = {};

        // Password is required
        if (!userForm.password)
            newErrors.password = t(
                "users.form.password-change.validation.password.required"
            );
        // Confirm password is required
        if (!userForm.confirmPassword)
            newErrors.confirmPassword = t(
                "users.form.password-change.validation.confirm-password.required"
            );
        // Password and confirm password must be the same
        if (userForm.password !== userForm.confirmPassword)
            newErrors.confirmPassword = t(
                "users.form.password-change.error.confirm-password.different"
            );
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle password
    const handlePassword = (value) => {
        const newErrors = {};
        if (!validatePassword(value))
            newErrors.password = t(
                "users.form.password-change.validation.password.invalid"
            );
        setErrors(newErrors);
        setUserForm({
            ...userForm,
            password: value,
        });
    };

    // Handle confirm password
    const handleConfirmPassword = (value) => {
        const newErrors = {};
        if (userForm.password !== value)
            newErrors.confirmPassword = t(
                "users.form.password-change.validation.confirm-password.different"
            );
        setErrors(newErrors);
        setUserForm({
            ...userForm,
            confirmPassword: value,
        });
    };

    return (
        <>
            <div className="activation-container">
                <div className="activation-title">
                    <h1>{t("users.forgot-password.title")}</h1>
                </div>
                <div className="activation-image">
                    <img src={Logo} alt="activation" />
                </div>
                <Container style={{ maxWidth: "700px", margin: "20px" }}>
                    <Row>
                        <Col>
                            <FloatingLabel
                                controlId="userPassword"
                                label={t("users.form.user-data.password")}
                                className="mb-3"
                            >
                                <Form.Control
                                    type="password"
                                    placeholder={t(
                                        "users.form.user-data.password"
                                    )}
                                    value={userForm.password || ""}
                                    onChange={(e) => {
                                        handlePassword(e.target.value);
                                    }}
                                    isInvalid={!!errors.password}
                                    autoComplete="new-password"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FloatingLabel
                                controlId="userConfirmPassword"
                                label={t(
                                    "users.form.user-data.confirm-password"
                                )}
                                className="mb-3"
                            >
                                <Form.Control
                                    type="password"
                                    placeholder={t(
                                        "users.form.user-data.confirm-password"
                                    )}
                                    value={userForm.confirmPassword || ""}
                                    onChange={(e) => {
                                        handleConfirmPassword(e.target.value);
                                    }}
                                    isInvalid={!!errors.confirmPassword}
                                    autoComplete="new-password"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.confirmPassword}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Button
                            size="lg"
                            variant="primary"
                            onClick={handleFormSubmit}
                            style={{ width: "200px" }}
                            disabled={btnDisable}
                        >
                            {t("users.form.footer.save")}
                        </Button>
                    </Row>
                </Container>
            </div>
        </>
    );
}
