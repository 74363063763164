import React from "react";

// Import the bootstrap component
import { Spinner } from "react-bootstrap";

export default function Loading() {
    return (
        <>
            <div style={style.div}>
                <Spinner style={style.spinner} animation="border" role="status"></Spinner>
            </div>
        </>
    );
}

const style = {
    div: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 174px)",
    },
    spinner: {
        width: "100px",
        height: "100px",
    },
}
