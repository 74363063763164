import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

// Importa os componentes do Bootstrap
import { Navbar, Container, Nav, Form, Button } from "react-bootstrap";

// Importa o hook de tradução
import { useTranslation } from "react-i18next";

// Importa o arquivo de estilos
import "./MenuClients.css";
import { PeopleFill, PlusCircle, Search } from "react-bootstrap-icons";

export default function MenuClients({ searsh, clean }) {
    // Hooks for translation
    const { t } = useTranslation();

    // Hook de navegação
    const navigate = useNavigate();

    // Hook para o valor do input de pesquisa
    const [searchValue, setSearchValue] = useState("");
    const handleSearchChange = (event) => {
        event.preventDefault();
        setSearchValue(event.target.value);
    };
    const handleInput = (event) => {
        if (event.target.value === "") {
            clean();
        }
    };
    const handleFormSubmit = (event) => {
        event.preventDefault();
        searsh(searchValue);
    };

    return (
        <Navbar expand="lg" className="bg-body-tertiary menu-client">
            <Container fluid>
                <Navbar.Brand>
                    <PeopleFill size={50} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="me-auto my-2 my-lg-0"
                        style={{ maxHeight: "100px" }}
                        navbarScroll
                    >
                        <Button
                            variant="primary"
                            onClick={() => navigate("/clients/create")}
                        >
                            <PlusCircle style={{ marginRight: "10px" }} />
                            {t("client.menu.new")}
                        </Button>
                    </Nav>
                    <Form className="d-flex" onSubmit={handleFormSubmit}>
                        <Form.Control
                            type="search"
                            placeholder={t("client.menu.search")}
                            className="me-2"
                            aria-label="Search"
                            onChange={handleSearchChange}
                            onInput={handleInput}
                        />
                        <Button
                            variant="outline-success"
                            onClick={handleFormSubmit}
                        >
                            <Search />
                        </Button>
                    </Form>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
