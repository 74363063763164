// Contexto de Autenticação (AuthContext)
import React, { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(() => {
        // Get the auth object from the local storage
        const auth = JSON.parse(localStorage.getItem("auth"));

        // Check if the token is expired
        if (auth && new Date(auth.expired_at).getTime() < Date.now()) {
            localStorage.removeItem("auth");
            return null;
        }

        // Return the auth object
        return auth;
    });

    const login = (authData) => {
        setAuth(authData);
        localStorage.setItem("auth", JSON.stringify(authData));
    };

    const logout = () => {
        setAuth(null);
        localStorage.removeItem("auth");
    };

    return (
        <AuthContext.Provider value={{ auth, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
