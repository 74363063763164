import React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    Outlet,
} from "react-router-dom";

// Import the components
import "../src/Locates/locate";
import { useAuth } from "./Auth/AuthProvider";
import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Settings from "./Pages/Settings/Settings";
import ClientList from "./Pages/Clients/ClientList";
import ClientCad from "./Pages/Clients/ClientCad";
import NotFound from "./Pages/NotFound/NotFound";
import ToastAlert from "./Helpers/Alert/ToastAlert";
import Users from "./Pages/Users/Users";
import UserActivation from "./Pages/Users/UserActivation";
import UserForgot from "./Pages/Users/UserForgot";
import UserRecovery from "./Pages/Users/UserRecovery";

// Defining private routes
const PrivateRoute = () => {
    const { auth } = useAuth();
    return auth ? <Outlet /> : <Navigate to="/login" />;
};

function App() {
    return (
        <BrowserRouter>
            <ToastAlert />
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/verification-user" element={<UserActivation />} />
                <Route path="/forgot-password" element={<UserForgot />} />
                <Route path="/recovery" element={<UserRecovery />} />
                <Route path="/" element={<PrivateRoute />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/clients" element={<ClientList />}></Route>
                    <Route path="/clients/:id/edit" element={<ClientCad />} />
                    <Route path="/clients/create" element={<ClientCad />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
