import React, { createContext, useContext, useState } from "react";

// Create context
const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {

    // State to store alerts
    const [alerts, setAlerts] = useState([]);

    // Add alert
    const addAlert = (type, title, message) => {
        setAlerts((prevAlerts) => [
            ...prevAlerts,
            { id: Date.now(), type, title, message, show: true },
        ]);
    };

    // Remove alert
    const removeAlert = (id) => {
        setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
    };

    // Return provider
    return (
        <AlertContext.Provider value={{ alerts, addAlert, removeAlert }}>
            {children}
        </AlertContext.Provider>
    );
};
