import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Importa os componentes do Bootstrap
import {
    Container,
    Nav,
    Navbar,
    Offcanvas,
    Form,
    Button,
    Row,
} from "react-bootstrap";

// Importa os icones
import {
    BoxArrowLeft,
    MoonStars,
    Sun,
    PersonFill,
    List,
    XLg,
} from "react-bootstrap-icons";

import IconPtBr from "../../../../Assets/Icons/br.png";
import IconEnUs from "../../../../Assets/Icons/us.png";
import IconEsEs from "../../../../Assets/Icons/es.png";

// Importa os métodos de requisição
import { getData, postData } from "../../../../Services/apiService";
import { useAuth } from "../../../../Auth/AuthProvider";
import { useAlert } from "../../../../Helpers/Alert/AlertContext";

// Importa o arquivo de estilos
import "./HeaderBar.css";
import UserPasswordForm from "../../../Users/Components/UserPasswordForm";

export default function HeaderBar({ collapsed, onCollapsedMenu, onShowMenu }) {
    // Hook para os alertas
    const { addAlert } = useAlert();

    // Hook para o tema
    const [theme, setTheme] = useState("ligth");
    const handleTheme = () => {
        // Define o tema
        const localTheme = theme === "dark" ? "ligth" : "dark";

        // Atualiza a configuracao do tema
        const configTheme = postData("/user/config", {
            key: "theme",
            value: localTheme,
        });
        configTheme.then((response) => {
            if (response.status === 200) {
                // Atualiza o tema
                setTheme(localTheme);
                localStorage.setItem("theme", localTheme);
                document.documentElement.setAttribute(
                    "data-bs-theme",
                    localTheme
                );
                return;
            }

            // Exibe o erro
            addAlert("danger", t("user.theme.error"), response.body);
        });
    };

    // Hook para a autenticação
    const { auth, logout } = useAuth();

    // Hook para a navegação
    const navigate = useNavigate();

    // Hook para a tradução
    const { t, i18n } = useTranslation();

    // Carrega os dados do usuário
    const [user, setUser] = useState({});
    useEffect(() => {
        // Define o tema
        setTheme(localStorage.getItem("theme"));
        setUser(auth.user);
        
        // eslint-disable-next-line
    }, []);

    // Função para o idioma
    const handleLanguage = (language) => {
        // Atualiza a configuracao do idioma
        const configLanguage = postData("/user/config", {
            key: "language",
            value: language,
        });

        configLanguage.then((response) => {
            if (response.status === 200) {
                i18n.changeLanguage(language);
                localStorage.setItem("language", language);
                return;
            }

            // Exibe o erro
            addAlert("danger", t("user.language.error"), response.body);
        });
    };

    // Função para o logout
    const handleLogout = () => {
        console.log("Logout");
        // Requisição para o logout
        const apiLogout = getData(`/logout`);
        apiLogout.then((response) => {
            if (response.status === 200) {
                logout();
                navigate("/login");
                return;
            }
        });
    };

    // Função para a troca de senha
    const [showPasswordChange, setShowPasswordChange] = useState(false);
    const handlePassword = () => {
        setShowPasswordChange(true);
        console.log("Password");
    }
    const handleClosePassword = () => {
        setShowPasswordChange(false);
    }

    return (
        <>
            <Navbar
                expand={false}
                bg={theme}
                data-bs-theme={theme}
                className="bg-body-tertiary mb-3"
                style={styles.navbar}
            >
                <Container fluid>
                    <Button
                        variant="outline-secondary"
                        onClick={onCollapsedMenu}
                        className="d-none d-md-block header-button"
                    >
                        {collapsed ? <XLg /> : <List />}
                    </Button>
                    <Button
                        variant="outline-secondary"
                        onClick={onShowMenu}
                        className="d-md-none btn-mobile"
                    >
                        <List />
                    </Button>
                    <Form
                        className={`ms-auto d-flex align-items-center text-align-center ${
                            theme ? "text-light" : "text-dark"
                        }`}
                    >
                        <Form.Label className="me-2 sun-icon">
                            <Sun />
                        </Form.Label>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            checked={theme === "dark"}
                            onChange={handleTheme}
                        />
                        <Form.Label className="me-4 moon-icon">
                            <MoonStars />
                        </Form.Label>
                    </Form>
                    <Navbar.Toggle
                        aria-controls={`offcanvasNavbar-expand`}
                        style={styles.userButton}
                    >
                        <PersonFill />
                    </Navbar.Toggle>
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-false`}
                        aria-labelledby={`offcanvasNavbarLabel-expand`}
                        placement="end"
                        bg={theme}
                        data-bs-theme={theme}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
                                {user.name}
                            </Offcanvas.Title>
                        </Offcanvas.Header>

                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1">
                                <Row className="lang-menu">
                                    <button
                                        onClick={() => handleLanguage("pt-BR")}
                                    >
                                        <img
                                            src={IconPtBr}
                                            alt="Portuguese"
                                            className="me-2"
                                        />
                                    </button>
                                    <button
                                        onClick={() => handleLanguage("en")}
                                    >
                                        <img
                                            src={IconEnUs}
                                            alt="English"
                                            className="me-2"
                                        />
                                    </button>
                                    <button
                                        onClick={() => handleLanguage("es")}
                                    >
                                        <img src={IconEsEs} alt="Spanish" />
                                    </button>
                                </Row>
                                <Nav.Link className="user-menu" onClick={handlePassword}>{t("user.password")}</Nav.Link>
                                <Nav.Link className="user-menu">Link</Nav.Link>
                                <Button
                                    onClick={handleLogout}
                                    className="btn-logout"
                                >
                                    <BoxArrowLeft />
                                    {t("user.logout")}
                                </Button>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
            <UserPasswordForm user={user} show={showPasswordChange} handleClose={handleClosePassword}/>
        </>
    );
}

const styles = {
    navbar: {
        height: "80px",
        padding: "13px",
        paddingRight: 0,
        textAlign: "center",
        fontSize: "20px",
        boxShadow: "0 5px 10px rgba(0,0,0,0.2)",
    },
    userButton: {
        height: "50px",
        width: "50px",
        borderRadius: "50%",
        border: "2px solid",
    },
};
