import React, { useState, useCallback, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Row, Col, FloatingLabel, Form } from "react-bootstrap";
import InputMask from "react-input-mask";
import { setValue, validateEmail } from "../../../../Helpers/Form";
import { getData, postData, putData } from "../../../../Services/apiService";
import { useAlert } from "../../../../Helpers/Alert/AlertContext";
import { getAddress } from "../../../../Services/Cep";

export default function ClientFrom({ data }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { addAlert } = useAlert();
    const [client, setClient] = useState(
        data || {
            is_active: true,
            legal_entity: false,
            address: {
                zip_code: "",
                state: {},
                city: {},
            },
            contact: {},
        }
    );

    const handleInputChange = (field) => (e) => {
        let value = e.target.value;

        if (field === "cpf_cnpj") value = value.replace(/\D/g, "");
        if (field === "address.zip_code") value = value.replace(/\D/g, "");
        if (field === "address.state")
            value = states.find((state) => state.id === parseInt(value));
        if (field === "address.city")
            value = cities.find((city) => city.id === parseInt(value));
        if (field === "contact.phone") value = value.replace(/\D/g, "");
        if (field === "contact.cell_phone") value = value.replace(/\D/g, "");

        setClient((prevClient) => {
            const updatedClient = setValue(prevClient, field, value);
            return updatedClient;
        });
    };

    // Estados
    const [states, setStates] = useState([]);
    const getListStates = useCallback(async () => {
        const getStates = getData("/state", {});
        getStates.then((response) => {
            if (response.status === 200) {
                setStates(response.body.data);
                return;
            }
            // Emite alerta caso ocorra algum erro
            addAlert(
                "danger",
                t("error.state.title"),
                t("error.state.message")
            );
        });
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        getListStates();
    }, [getListStates]);

    // Cidades
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState("");
    const getListCities = async (id_state) => {
        const getCities = getData(`/city?state=${id_state}`, {});
        getCities.then((response) => {
            if (response.status === 200) {
                setCities(response.body.data);
                return;
            }
            addAlert("danger", t("error.city.title"), t("error.city.message"));
        });
    };
    useEffect(() => {
        // Limpoa os dados da cidade
        setClient((prevClient) => ({
            ...prevClient,
            address: {
                ...prevClient.address,
                city: {},
            },
        }));

        // busca a nova lista de cidades
        if (client.address.state.id) {
            getListCities(client.address.state.id);
        }

        // eslint-disable-next-line
    }, [client.address.state]);

    // Atualiza dados da cidade.
    useEffect(() => {
        if (city) {
            const citySelected = cities.find((c) => c.cod_ibge === city);
            console.log(citySelected);
            setClient((prevClient) => ({
                ...prevClient,
                address: {
                    ...prevClient.address,
                    city: citySelected,
                },
            }));
        }
        // eslint-disable-next-line
    }, [cities, city]);

    // Endereco
    const handleAddress = () => {
        const zipCodeValue = client.address.zip_code;
        if (zipCodeValue.length === 8) {
            // Realiza a consulta do CEP
            const address = getAddress(zipCodeValue);
            address.then(async (response) => {
                if (response.error) {
                    addAlert(
                        "danger",
                        t("error.state.title"),
                        t(response.message)
                    );
                    console.error(response.massage);
                    return;
                }

                console.log(response);

                // Pega os dados do estado
                const state = states.find((state) => state.uf === response.uf);
                if (!state) {
                    addAlert(
                        "danger",
                        t("error.state.title"),
                        t("error.state.message")
                    );
                    return;
                }

                console.log(state);

                // Atualiza a cidade
                setCity(response.ibge);

                // Atualiza os campos do endereco
                setClient((prevClient) => ({
                    ...prevClient,
                    address: {
                        ...prevClient.address,
                        zip_code: response.cep,
                        state: state,
                        neighborhood: response.bairro,
                        street: response.logradouro,
                    },
                }));
            });
        }
        // eslint-disable-next-line
    };

    // Valida os campos obrigatorios
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        const newErrors = {};
        if (!client.name) newErrors.name = t("client.form.error.name.required");
        if (!client.cpf_cnpj)
            client.legal_entity
                ? (newErrors.document = t("client.form.error.cnpj.required"))
                : (newErrors.document = t("client.form.error.cpf.required"));
        if (client.contact.email && !validateEmail(client.contact.email))
            newErrors.email = t("client.form.error.email.invalid");
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            let sendClient = null;
            if (client.id) {
                sendClient = putData("/client", client.id, client);
            } else {
                sendClient = postData("/client", client);
            }
            sendClient.then((response) => {
                console.log(response);
                if (response.status === 200) {
                    addAlert(
                        "success",
                        t("client.form.success.title"),
                        t("client.form.success.message")
                    );
                    navigate("/clients");
                    return;
                }

                if (response.status === 409) {
                    addAlert(
                        "danger",
                        t("client.form.error.title"),
                        t("client.form.error.duplicate")
                    );
                    return;
                }

                if (response.status === 400) {
                    if (response.body === "Invalid CPF/CNPJ") {
                        addAlert(
                            "danger",
                            t("client.form.error.title"),
                            t("client.form.error.doc-invalid")
                        );
                        return;
                    }
                }

                addAlert(
                    "danger",
                    t("client.form.error.title"),
                    t("client.form.error.internal")
                );
            });
        }
    };

    return (
        <div className="client-form">
            <Form>
                <div className="company-data">
                    <span className="company-data-title">
                        {t("client.form.company-data.title")}
                    </span>
                    <Row className="mb-3">
                        <Col sm={6}>
                            <FloatingLabel
                                controlId="floatingInputName"
                                label={t(
                                    client.legal_entity
                                        ? "client.form.company-data.social_name"
                                        : "client.form.company-data.name"
                                )}
                            >
                                <Form.Control
                                    type="text"
                                    placeholder={t(
                                        client.legal_entity
                                            ? "client.form.company-data.social_name"
                                            : "client.form.company-data.name"
                                    )}
                                    value={client.name || ""}
                                    onChange={handleInputChange("name")}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel
                                controlId="floatingInputCpfCnpj"
                                label={t(
                                    client.legal_entity
                                        ? "client.form.company-data.cnpj"
                                        : "client.form.company-data.cpf"
                                )}
                            >
                                <InputMask
                                    mask={
                                        client.legal_entity
                                            ? "99.999.999/9999-99"
                                            : "999.999.999-99"
                                    }
                                    value={client.cpf_cnpj || ""}
                                    onChange={handleInputChange("cpf_cnpj")}
                                    placeholder={t(
                                        client.legal_entity
                                            ? "client.form.company-data.cnpj"
                                            : "client.form.company-data.cpf"
                                    )}
                                >
                                    {(inputProps) => (
                                        <Form.Control
                                            {...inputProps}
                                            isInvalid={!!errors.document}
                                        />
                                    )}
                                </InputMask>
                                <Form.Control.Feedback type="invalid">
                                    {errors.document}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <Form.Check
                                label={t("client.form.company-data.pf")}
                                name="group1"
                                type="radio"
                                id={`reverse-radio-1`}
                                checked={!client.legal_entity}
                                onChange={() =>
                                    setClient((prevClient) => ({
                                        ...prevClient,
                                        legal_entity: false,
                                    }))
                                }
                            />
                            <Form.Check
                                label={t("client.form.company-data.pj")}
                                name="group1"
                                type="radio"
                                id={`reverse-radio-2`}
                                checked={client.legal_entity}
                                onChange={() =>
                                    setClient((prevClient) => ({
                                        ...prevClient,
                                        legal_entity: true,
                                    }))
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <FloatingLabel
                                controlId="floatingInputFantasyName"
                                label={t(
                                    client.legal_entity
                                        ? "client.form.company-data.fantasy_name"
                                        : "client.form.company-data.surname"
                                )}
                            >
                                <Form.Control
                                    type="text"
                                    placeholder={t(
                                        client.legal_entity
                                            ? "client.form.company-data.fantasy_name"
                                            : "client.form.company-data.surname"
                                    )}
                                    value={client.last_name || ""}
                                    onChange={handleInputChange("last_name")}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel
                                controlId="floatingInputIe"
                                label={t(
                                    client.legal_entity
                                        ? "client.form.company-data.ie"
                                        : "client.form.company-data.id"
                                )}
                                className=""
                            >
                                <Form.Control
                                    type="number"
                                    placeholder={t(
                                        client.legal_entity
                                            ? "client.form.company-data.ie"
                                            : "client.form.company-data.id"
                                    )}
                                    pattern="\d*"
                                    value={client.ie || ""}
                                    onChange={handleInputChange("ie")}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col className="input-check">
                            <Form.Check
                                type="checkbox"
                                label={t("client.form.company-data.is_active")}
                                checked={client.is_active}
                                onChange={() => {
                                    setClient((prevClient) => ({
                                        ...prevClient,
                                        is_active: !prevClient.is_active,
                                    }));
                                }}
                            />
                        </Col>
                    </Row>
                </div>

                <div className="company-data">
                    <span className="company-data-title">
                        {t("client.form.address-data.title")}
                    </span>
                    <Row className="mb-3">
                        <Col sm={2}>
                            <FloatingLabel
                                controlId="floatingInputCep"
                                label={t("client.form.address-data.zip-code")}
                                className=""
                            >
                                <InputMask
                                    mask={"99999-999"}
                                    value={
                                        client.address &&
                                        client.address.zip_code &&
                                        client.address.zip_code
                                    }
                                    onChange={handleInputChange(
                                        "address.zip_code"
                                    )}
                                    onBlur={handleAddress}
                                    placeholder={t(
                                        "client.form.address-data.zip-code"
                                    )}
                                >
                                    {(inputProps) => (
                                        <Form.Control {...inputProps} />
                                    )}
                                </InputMask>
                            </FloatingLabel>
                        </Col>
                        <Col sm={1}>
                            <FloatingLabel
                                controlId="floatingSelectUf"
                                label={t("client.form.address-data.uf")}
                                className=""
                            >
                                <Form.Select
                                    aria-label="UF"
                                    onChange={handleInputChange(
                                        "address.state"
                                    )}
                                    value={
                                        client.address.state.id
                                            ? client.address.state.id
                                            : 0
                                    }
                                >
                                    <option value="0"></option>
                                    {states &&
                                        states.map((state) => (
                                            <option
                                                key={state.id}
                                                value={state.id}
                                            >
                                                {state.uf}
                                            </option>
                                        ))}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Col sm={4}>
                            <FloatingLabel
                                controlId="floatingInputStateName"
                                label={t("client.form.address-data.state")}
                            >
                                <Form.Control
                                    disabled
                                    type="text"
                                    placeholder={t(
                                        "client.form.address-data.state"
                                    )}
                                    value={
                                        client.address.state.name
                                            ? client.address.state.name
                                            : ""
                                    }
                                />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel
                                controlId="floatingSelectCity"
                                label={t("client.form.address-data.city")}
                            >
                                <Form.Select
                                    aria-label="City"
                                    value={
                                        client.address.city &&
                                        client.address.city.id
                                            ? client.address.city.id
                                            : 0
                                    }
                                    onChange={handleInputChange("address.city")}
                                >
                                    <option value="0"></option>
                                    {cities &&
                                        cities.map((city) => (
                                            <option
                                                key={city.id}
                                                value={city.id}
                                            >
                                                {city.name}
                                            </option>
                                        ))}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={3}>
                            <FloatingLabel
                                controlId="floatingInputNeighborhood"
                                label={t(
                                    "client.form.address-data.neighborhood"
                                )}
                            >
                                <Form.Control
                                    type="text"
                                    value={
                                        client.address &&
                                        client.address.neighborhood
                                            ? client.address.neighborhood
                                            : ""
                                    }
                                    onChange={handleInputChange(
                                        "address.neighborhood"
                                    )}
                                    placeholder={t(
                                        "client.form.address-data.neighborhood"
                                    )}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col sm={8}>
                            <FloatingLabel
                                controlId="floatingInputStreet"
                                label={t("client.form.address-data.street")}
                                className=""
                            >
                                <Form.Control
                                    type="text"
                                    value={
                                        client.address &&
                                        client.address.street &&
                                        client.address.street
                                    }
                                    onChange={handleInputChange(
                                        "address.street"
                                    )}
                                    placeholder={t(
                                        "client.form.address-data.street"
                                    )}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel
                                controlId="floatingInputNumber"
                                label={t("client.form.address-data.number")}
                                className=""
                            >
                                <Form.Control
                                    type="text"
                                    value={
                                        client.address &&
                                        client.address.number &&
                                        client.address.number
                                    }
                                    onChange={handleInputChange(
                                        "address.number"
                                    )}
                                    placeholder={t(
                                        "client.form.address-data.number"
                                    )}
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FloatingLabel
                                controlId="floatingInputComplement"
                                label={t("client.form.address-data.complement")}
                                className=""
                            >
                                <Form.Control
                                    type="text"
                                    value={
                                        client.address &&
                                        client.address.complement &&
                                        client.address.complement
                                    }
                                    onChange={handleInputChange(
                                        "address.complement"
                                    )}
                                    placeholder={t(
                                        "client.form.address-data.complement"
                                    )}
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                </div>

                <div className="company-data">
                    <span className="company-data-title">
                        {t("client.form.address-data.title")}
                    </span>
                    <Row className="mb-3">
                        <Col sm={3}>
                            <FloatingLabel
                                controlId="floatingContactPhone"
                                label={t("client.form.contact-data.phone")}
                            >
                                <InputMask
                                    mask={"(99) 9999-9999"}
                                    value={
                                        client.contact.phone &&
                                        client.contact.phone
                                    }
                                    onChange={handleInputChange(
                                        "contact.phone"
                                    )}
                                    placeholder={t(
                                        "client.form.contact-data.phone"
                                    )}
                                >
                                    {(inputProps) => (
                                        <Form.Control {...inputProps} />
                                    )}
                                </InputMask>
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel
                                controlId="floatingInputContactEmail"
                                label={t("client.form.contact-data.email")}
                            >
                                <Form.Control
                                    type="email"
                                    placeholder={t(
                                        "client.form.contact-data.email"
                                    )}
                                    onChange={handleInputChange(
                                        "contact.email"
                                    )}
                                    value={
                                        client.contact.email
                                            ? client.contact.email
                                            : ""
                                    }
                                    isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FloatingLabel
                                controlId="floatingContactCellPhone"
                                label={t("client.form.contact-data.cell-phone")}
                            >
                                <InputMask
                                    mask={"(99) 99999-9999"}
                                    value={
                                        client.contact.cell_phone &&
                                        client.contact.cell_phone
                                    }
                                    onChange={handleInputChange(
                                        "contact.cell_phone"
                                    )}
                                    placeholder={t(
                                        "client.form.contact-data.cell-phone"
                                    )}
                                >
                                    {(inputProps) => (
                                        <Form.Control {...inputProps} />
                                    )}
                                </InputMask>
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel
                                controlId="floatingInputContactOther"
                                label={t("client.form.contact-data.other")}
                            >
                                <Form.Control
                                    type="email"
                                    placeholder={t(
                                        "client.form.contact-data.other"
                                    )}
                                    onChange={handleInputChange(
                                        "contact.other"
                                    )}
                                    value={
                                        client.contact.other
                                            ? client.contact.other
                                            : ""
                                    }
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                </div>
            </Form>
            <div className="company-footer">
                <button
                    className="btn btn-danger"
                    onClick={() => navigate("/clients")}
                >
                    {t("client.form.footer.cancel")}
                </button>
                <button
                    style={{ marginLeft: "10px" }}
                    className="btn btn-primary"
                    onClick={() => handleSubmit()}
                >
                    {t("client.form.footer.save")}
                </button>
            </div>
        </div>
    );
}
