import React, { useState } from "react";

import { Navbar, Container, Nav, Form, Button } from "react-bootstrap";
import { PersonBoundingBox, PlusCircle, Search } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export default function UserMenu({
    handleFormShow,
    searsh,
    clean,
    userClient,
    idClient,
}) {
    // Hooks for translation
    const { t } = useTranslation();

    // Hooks for search value
    const [searchValue, setSearchValue] = useState("");
    const handleSearchChange = (event) => {
        event.preventDefault();
        setSearchValue(event.target.value);
    };
    const handleInput = (event) => {
        if (event.target.value === "") {
            clean();
        }
    };
    const handleFormSubmit = (event) => {
        event.preventDefault();
        searsh(searchValue);
    };

    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary menu-client">
                <Container fluid>
                    <Navbar.Brand>
                        <PersonBoundingBox size={50} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{ maxHeight: "100px" }}
                            navbarScroll
                        >
                            {userClient && idClient ? (
                                <Button
                                    variant="primary"
                                    onClick={() => handleFormShow()}
                                >
                                    <PlusCircle
                                        style={{ marginRight: "10px" }}
                                    />
                                    {t("users.menu.new")}
                                </Button>
                            ) : null}
                        </Nav>
                        <Form className="d-flex" onSubmit={handleFormSubmit}>
                            <Form.Control
                                type="search"
                                placeholder={t("users.menu.search")}
                                className="me-2"
                                aria-label="Search"
                                onChange={handleSearchChange}
                                onInput={handleInput}
                            />
                            <Button
                                variant="outline-success"
                                onClick={handleFormSubmit}
                            >
                                <Search />
                            </Button>
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}
