import React, { useState, useEffect, useRef } from "react";
import "./DatePicker.css";
import { useTranslation } from "react-i18next";
import { FloatingLabel, Form } from "react-bootstrap";
import { format } from "date-fns";
import {
    Calendar2Week,
    ChevronDoubleLeft,
    ChevronDoubleRight,
} from "react-bootstrap-icons";

const DatePicker = ({ id, label, value, onChange, position }) => {
    const { t } = useTranslation();

    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getMonth());
    const [showCalendar, setShowCalendar] = useState(false);
    const [openDirection, setOpenDirection] = useState("down");
    const [mode, setMode] = useState("days"); // days, months, years
    const datepickerRef = useRef(null); // Referência ao DatePicker

    const daysOfWeek = [
        t("date.days.sunday"),
        t("date.days.monday"),
        t("date.days.tuesday"),
        t("date.days.wednesday"),
        t("date.days.thursday"),
        t("date.days.friday"),
        t("date.days.saturday"),
    ];

    const months = [
        t("date.months.january"),
        t("date.months.february"),
        t("date.months.march"),
        t("date.months.april"),
        t("date.months.may"),
        t("date.months.june"),
        t("date.months.july"),
        t("date.months.august"),
        t("date.months.september"),
        t("date.months.october"),
        t("date.months.november"),
        t("date.months.december"),
    ];

    useEffect(() => {
        if (value) {
            setCurrentMonth(new Date(value).getMonth());
            setCurrentYear(new Date(value).getFullYear());
        }
    }, [value]);

    useEffect(() => {
        // Função para fechar o calendário se clicar fora do DatePicker
        const handleClickOutside = (event) => {
            if (
                datepickerRef.current &&
                !datepickerRef.current.contains(event.target)
            ) {
                setMode("days");
                setShowCalendar(false);
            }
        };

        // Adiciona o event listener para cliques fora
        document.addEventListener("mousedown", handleClickOutside);

        // Limpa o event listener quando o componente desmontar
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [datepickerRef]);

    useEffect(() => {
        if (showCalendar) {
            // Verifica a posição do DatePicker
            const rect = datepickerRef.current.getBoundingClientRect();
            const spaceBelow = window.innerHeight - rect.bottom;
            const calendarHeight = 300; // Altura estimada do calendário

            if (spaceBelow < calendarHeight) {
                // Se não houver espaço suficiente para abrir para baixo, abre para cima
                setOpenDirection("up");
            } else {
                // Caso contrário, abre para baixo
                setOpenDirection("down");
            }
        }
    }, [showCalendar]);

    const generateCalendar = () => {
        const daysInMonth = new Date(
            currentYear,
            currentMonth + 1,
            0
        ).getDate();
        const firstDay = new Date(currentYear, currentMonth, 1).getDay();
        const days = [];

        for (let i = 0; i < firstDay; i++) {
            days.push(null);
        }

        for (let day = 1; day <= daysInMonth; day++) {
            days.push(day);
        }

        return days;
    };

    const handleDateClick = (day) => {
        if (day) {
            const date = new Date(currentYear, currentMonth, day);
            onChange(date);
            setShowCalendar(false);
        }
    };

    const handleMonthChange = (delta) => {
        const newMonth = (currentMonth + delta + 12) % 12;
        const newYear =
            currentMonth + delta < 0
                ? currentYear - 1
                : currentMonth + delta > 11
                ? currentYear + 1
                : currentYear;
        setCurrentMonth(newMonth);
        setCurrentYear(newYear);
    };

    const handleYearChange = (delta) => {
        setCurrentYear(currentYear + delta);
    };

    const handleDaysClick = () => {
        setMode("days");
    };

    const handleMonthClick = () => {
        setMode("months");
    };

    const handleYearClick = () => {
        setMode("years");
    };

    const selectMonth = (monthIndex) => {
        setCurrentMonth(monthIndex);
        setMode("days");
    };

    const selectYear = (year) => {
        setCurrentYear(year);
        setMode("months");
    };

    const renderCalendarHeader = () => {
        if (mode === "days") {
            return (
                <>
                    <button onClick={() => handleMonthChange(-1)}>
                        <ChevronDoubleLeft />
                    </button>
                    <span onClick={handleMonthClick}>
                        {months[currentMonth]} {currentYear}
                    </span>
                    <button onClick={() => handleMonthChange(1)}>
                        <ChevronDoubleRight />
                    </button>
                </>
            );
        } else if (mode === "months") {
            return (
                <>
                    <button onClick={() => handleYearChange(-1)}>
                        <ChevronDoubleLeft />
                    </button>
                    <span onClick={handleYearClick}>{currentYear}</span>
                    <button onClick={() => handleYearChange(1)}>
                        <ChevronDoubleRight />
                    </button>
                </>
            );
        } else if (mode === "years") {
            return (
                <>
                    <button onClick={() => handleYearChange(-10)}>
                        <ChevronDoubleLeft />
                    </button>
                    <span onClick={handleDaysClick}>{currentYear}</span>
                    <button onClick={() => handleYearChange(10)}>
                        <ChevronDoubleRight />
                    </button>
                </>
            );
        }
    };

    const renderDays = () => {
        return (
            <div className="calendar-days">
                {daysOfWeek.map((day) => (
                    <div className="day-name" key={day}>
                        {day}
                    </div>
                ))}
                {generateCalendar().map((day, index) => (
                    <div
                        className={`day ${
                            day === new Date(value)?.getDate() ? "selected" : ""
                        }`}
                        key={index}
                        onClick={() => handleDateClick(day)}
                    >
                        {day || ""}
                    </div>
                ))}
            </div>
        );
    };

    const renderMonths = () => {
        return (
            <div className="calendar-month">
                {months.map((month, index) => (
                    <div
                        className={`month ${
                            index === currentMonth ? "selected" : ""
                        }`}
                        key={index}
                        onClick={() => selectMonth(index)}
                    >
                        {month}
                    </div>
                ))}
            </div>
        );
    };

    const renderYears = () => {
        const years = [];
        for (let i = currentYear - 5; i <= currentYear + 5; i++) {
            years.push(i);
        }
        return (
            <div className="calendar-year">
                {years.map((year) => (
                    <div
                        className={`month ${
                            year === currentYear ? "selected" : ""
                        }`}
                        key={year}
                        onClick={() => selectYear(year)}
                    >
                        {year}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="datepicker" ref={datepickerRef}>
            <div className="datepicker-input-group">
                <FloatingLabel controlId={id} label={label}>
                    <Form.Control
                        type="text"
                        value={value ? format(value, t("date.format")) : ""}
                        readOnly
                        onClick={() => setShowCalendar(!showCalendar)}
                    />
                </FloatingLabel>
                <span>
                    <Calendar2Week />
                </span>
            </div>
            {showCalendar && (
                <div
                    className={
                        position === "center"
                            ? `calendar center ${openDirection}`
                            : position === "right"
                            ? `calendar right ${openDirection}`
                            : `calendar left ${openDirection}`
                    }
                >
                    <div className="calendar-header">
                        {renderCalendarHeader()}
                    </div>
                    <div className="calendar-body">
                        {mode === "days" && renderDays()}
                        {mode === "months" && renderMonths()}
                        {mode === "years" && renderYears()}
                    </div>
                    <div className="calendar-clean">
                        <button
                            onClick={() => {
                                onChange(null);
                                setCurrentMonth(new Date().getMonth());
                                setCurrentYear(new Date().getFullYear());
                                setShowCalendar(false);
                                setMode("days");
                            }}
                        >
                            {t("date.clear")}
                        </button>
                        <button
                            onClick={() => {
                                onChange(new Date());
                                setCurrentMonth(new Date().getMonth());
                                setCurrentYear(new Date().getFullYear());
                                setShowCalendar(false);
                                setMode("days");
                            }}
                        >
                            {t("date.today")}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DatePicker;
