import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { useAlert } from "./AlertContext"; // Ajuste o caminho para o contexto

export default function ToastAlert() {
    const { alerts, removeAlert } = useAlert();

    return (
        <ToastContainer position="top-end" style={{ marginTop: "20px", marginRight: "20px"}}>
            {alerts.map((alert) => (
                <Toast
                    key={alert.id}
                    onClose={() => removeAlert(alert.id)}
                    show={alert.show}
                    delay={5000}
                    autohide={true}
                    bg={alert.type}
                >
                    <Toast.Header>
                        <strong className="me-auto">{alert.title}</strong>
                    </Toast.Header>
                    <Toast.Body>
                        {typeof alert.message === "object"
                            ? JSON.stringify(alert.message)
                            : alert.message}
                    </Toast.Body>
                </Toast>
            ))}
        </ToastContainer>
    );
}
