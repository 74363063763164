import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { postData } from "../../Services/apiService";
import Loading from "../Layout/Components/Loading";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import Logo from "../../Assets/Images/logo_primus.png";

export default function UserActivation() {
    const { t } = useTranslation();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    const token = queryParams.get("token");

    const [loading, setLoading] = React.useState(true);
    const [status, setStatus] = React.useState("error");
    const message =
        status === "success"
            ? t("users.active.success")
            : t("users.active.error");

    useEffect(() => {
        if (id && token) {
            const active = postData("/user/active", {
                id_user: parseInt(id),
                token: token,
            });
            active.then((response) => {
                if (response.status === 200) {
                    setStatus("success");
                    setLoading(false);
                }

                console.log(response);
            });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className="activation-container">
                    <div className="activation-title">
                        <h1>{t("users.active.title")}</h1>
                    </div>
                    <div className="activation-image">
                        <img src={Logo} alt="activation" />
                    </div>
                    <div className={`message-box ${status}`}>
                        <h2>{message}</h2>
                    </div>

                    <Button href="/login" variant="primary" size="lg">
                        {t("users.active.button")}
                    </Button>
                </div>
            )}
        </>
    );
}
