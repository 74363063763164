import React, { useState } from "react";
import {
    Modal,
    Button,
    Container,
    Row,
    Col,
    FloatingLabel,
    Form,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { validatePassword } from "../../../Helpers/Form";
import { putData } from "../../../Services/apiService";
import { useAlert } from "../../../Helpers/Alert/AlertContext";

export default function UserPasswordForm({ user, show, handleClose }) {
    const { t } = useTranslation(); // Hook para tradução
    const { addAlert } = useAlert(); // Hook para os alertas

    // Hook para os dados do formulário
    const [userForm, setUserForm] = useState({});

    // Enviar dados do formulário
    const [btnDisable, setBtnDisable] = useState(false);
    const handleFormSubmit = () => {
        if (validateForm()) {
            setBtnDisable(true);
            // Envia os dados do formulário
            const changePassword = putData(`/user/password/${user.id}`, {
                password: userForm.password,
                confirm_password: userForm.confirmPassword,
            });
            changePassword.then((response) => {
                if (response.status === 200) {
                    addAlert(
                        "success",
                        t("users.form.password-change.success.title"),
                        t("users.form.password-change.success.message")
                    );
                    handleClose();
                    setUserForm({});
                    setBtnDisable(false);
                } else {
                    addAlert(
                        "danger",
                        t("users.form.password-change.error.title") +
                            " " +
                            response.status,
                        response.body
                    );
                    setBtnDisable(false);
                }
            });
        }
    };

    const [errors, setErrors] = useState({});
    const validateForm = () => {
        const newErrors = {};

        // Password is required
        if (!userForm.password)
            newErrors.password = t(
                "users.form.password-change.validation.password.required"
            );
        // Confirm password is required
        if (!userForm.confirmPassword)
            newErrors.confirmPassword = t(
                "users.form.password-change.validation.confirm-password.required"
            );
        // Password and confirm password must be the same
        if (userForm.password !== userForm.confirmPassword)
            newErrors.confirmPassword = t(
                "users.form.password-change.error.confirm-password.different"
            );
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle password
    const handlePassword = (value) => {
        const newErrors = {};
        if (!validatePassword(value))
            newErrors.password = t(
                "users.form.password-change.validation.password.invalid"
            );
        setErrors(newErrors);
        setUserForm({
            ...userForm,
            password: value,
        });
    };

    // Handle confirm password
    const handleConfirmPassword = (value) => {
        const newErrors = {};
        if (userForm.password !== value)
            newErrors.confirmPassword = t(
                "users.form.password-change.validation.confirm-password.different"
            );
        setErrors(newErrors);
        setUserForm({
            ...userForm,
            confirmPassword: value,
        });
    };

    // Handle cancel form
    const handleFormCancel = () => {
        setUserForm({});
        handleClose();
    };

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                aria-labelledby="example-modal-sizes-title-lg"
                className="modal-create-user"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("users.form.password-change.title") +
                            " " +
                            user.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <FloatingLabel
                                    controlId="userPassword"
                                    label={t("users.form.user-data.password")}
                                >
                                    <Form.Control
                                        type="password"
                                        placeholder={
                                            t("users.form.user-data.password") +
                                            " " +
                                            user.name
                                        }
                                        value={userForm.password || ""}
                                        onChange={(e) => {
                                            handlePassword(e.target.value);
                                        }}
                                        isInvalid={!!errors.password}
                                        autoComplete="new-password"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.password}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel
                                    controlId="userConfirmPassword"
                                    label={t(
                                        "users.form.user-data.confirm-password"
                                    )}
                                >
                                    <Form.Control
                                        type="password"
                                        placeholder={t(
                                            "users.form.user-data.confirm-password"
                                        )}
                                        value={userForm.confirmPassword || ""}
                                        onChange={(e) => {
                                            handleConfirmPassword(
                                                e.target.value
                                            );
                                        }}
                                        isInvalid={!!errors.confirmPassword}
                                        autoComplete="new-password"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.confirmPassword}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        onClick={handleFormCancel}
                        disabled={btnDisable}
                    >
                        {t("users.form.footer.cancel")}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleFormSubmit}
                        disabled={btnDisable}
                    >
                        {t("users.form.footer.save")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
