import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en/translation.json";
import ptBr from "./pt-br/translation.json";
import es from "./es/translation.json";

const browserLanguage = navigator.language || navigator.userLanguage;

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: en,
        },
        "pt-BR": {
            translation: ptBr,
        },
        es: {
            translation: es,
        },
    },
    lng: browserLanguage,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
