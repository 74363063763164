import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

// Import the components Bootstrap
import { Pagination, Form, Row, Col } from "react-bootstrap";

// Import the translation hook
import { useTranslation } from "react-i18next";

// Import the FooterClients.css file
import "./TableFooter.css";

export default function TableFooter({ table, countColumns, reload }) {
    // Hook to use the translation functions
    const { t } = useTranslation();

    // Check if the screen is small
    const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });

    // Set the page number
    const [internalPage, setInternalPage] = useState(
        JSON.parse(localStorage.getItem(table))
    );
    useEffect(() => {
        setInternalPage(JSON.parse(localStorage.getItem(table)));
        // eslint-disable-next-line
    }, [reload]);

    // Atualiza o valor do Limit
    const handleLimit = (limit) => {
        let newInternalPage = internalPage;
        newInternalPage.page = 1;
        newInternalPage.limit = limit.target.value;
        setInternalPage(newInternalPage);
        localStorage.setItem(table, JSON.stringify(newInternalPage));
        reload();
    };

    // Atualiza o valor da página
    const handlePageChange = (page) => {
        let newInternalPage = internalPage;
        newInternalPage.page = page;
        setInternalPage(newInternalPage);
        localStorage.setItem(table, JSON.stringify(newInternalPage));
        reload();
    };
    useEffect(() => {
        if (internalPage.lastPage === 0) {
            setInternalPage((prevInternalPage) => ({
                ...prevInternalPage,
                lastPage: 1,
            }));
        }
    }, [internalPage]);

    return (
        <footer className="table-footer">
            <Row className="table-links">
                <Col sm={6} className="table-pagination">
                    <Pagination size={isSmallScreen ? "sm" : "lg"}>
                        {internalPage.page > 3 ? (
                            <>
                                <Pagination.First
                                    onClick={() => handlePageChange(1)}
                                />
                                <Pagination.Prev
                                    onClick={() =>
                                        handlePageChange(internalPage.page - 1)
                                    }
                                />
                                {!isSmallScreen && <Pagination.Ellipsis />}
                            </>
                        ) : null}
                        {Array.from(
                            { length: internalPage.lastPage },
                            (_, i) => i + 1
                        ).map((i) =>
                            internalPage.page - 3 < i &&
                            i < internalPage.page + 3 ? (
                                <Pagination.Item
                                    key={i}
                                    active={i === internalPage.page}
                                    onClick={() => handlePageChange(i)}
                                >
                                    {i}
                                </Pagination.Item>
                            ) : null
                        )}
                        {internalPage.page < internalPage.lastPage - 2 ? (
                            <>
                                {!isSmallScreen && <Pagination.Ellipsis />}
                                <Pagination.Next
                                    onClick={() =>
                                        handlePageChange(internalPage.page + 1)
                                    }
                                />
                                <Pagination.Last
                                    onClick={() =>
                                        handlePageChange(internalPage.lastPage)
                                    }
                                />
                            </>
                        ) : null}
                    </Pagination>
                </Col>
                <Col sm={2} className="table-limit">
                    <Form.Select
                        size={isSmallScreen ? "sm" : "lg"}
                        value={internalPage.limit}
                        onChange={handleLimit}
                    >
                        <option value={10}>10</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={internalPage.totalItems}>
                            {t("table.pagination.footer-all")}
                        </option>
                    </Form.Select>
                </Col>
                <Col sm={4} className="table-totals">
                    <div>
                        <span>{t("table.pagination.footer-items")} </span>
                        <span style={{ fontWeight: "bold" }}>
                            {internalPage.totalItems}{" "}
                        </span>
                    </div>
                    <div>
                        <span>{t("table.pagination.footer-pages")} </span>
                        <span style={{ fontWeight: "bold" }}>
                            {internalPage.lastPage}
                        </span>
                    </div>
                </Col>
            </Row>
        </footer>
    );
}
